import { cookies } from 'next/headers'

type NextCookies = ReturnType<typeof cookies>

export const safeDecodeURIComponent = (str: string = '') => {
    try {
        return decodeURIComponent(str)
    } catch (e) {}
    return str
}

export const getCookieString = (cookies: NextCookies) => {
    return cookies
        .getAll()
        .map(({ name, value }) => `${name}=${value}`)
        .join('; ')
}

export type Cookies = string | NextCookies

/**
 * util mostly just to set cookies correctly on client and server
 */
export const GET = (cookie: Cookies = ''): RequestInit => {
    if (typeof cookie === 'object') {
        cookie = getCookieString(cookie as NextCookies)
    }
    return {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            cookie: cookie || (typeof document === 'undefined' ? '' : document?.cookie),
        },
    }
}
